.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#plotContainer{
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes flash{
  from {background-color: white;}
  to {background-color: #f44336;}
}
